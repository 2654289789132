import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Producto } from "../model/producto.model";
//enviroment
import { environment } from "../../environments/environment";
//formulario
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
/** RXJS */
import { Observable, of, from, Subject, forkJoin, BehaviorSubject } from "rxjs";
import { tap, map, mergeMap, take } from "rxjs/operators";
import { ajax } from "rxjs/ajax";
//lodash
import * as _ from "lodash";

@Injectable({
  providedIn: "root",
})
export class ProfesionalService {
  constructor(private http: HttpClient, private _formBuilder: FormBuilder) {}
  public url: string = environment.production.serviceUrl;
  private settings = environment.production.settings;

  subject: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
  array$: Observable<any> = this.subject.asObservable();

  getProfesional() {
    return this.http
      .get(this.url + `dentistas`, this.settings)
      .pipe(map((result: Response) => result));
  }

  getProfesionalById(id: number) {
    return this.http
      .get(this.url + `dentistas/${id}`, this.settings)
      .pipe(map((result: Response) => result));
  }


  // FORMULARIO
  formulario: FormGroup = new FormGroup({
    id: new FormControl(null),
    nombre: new FormControl("", Validators.required),
    apellido: new FormControl("", Validators.required),
    especialidades: new FormControl("", Validators.required),
    estado: new FormControl(true, Validators.required),
  });

  initializeFormGroup() {
    this.formulario.setValue({
      id: null,
      nombre: "",
      apellido: "",
      especialidades: "",
      estado: true,
    });
  }

 
  insertProfesional(profesional) {
  }

  updateProfesional(profesional) {
  }

  //DELETE
  deleteProducto(id: Number) {
    //this.contactList.remove(id);
  }

  populateForm(profesional) {
    const { id, nombre, apellido, especialidades, estado } = profesional;
    if (localStorage.getItem("profesional") === null) {
      localStorage.setItem("profesional", JSON.stringify(profesional));
    } else {
      localStorage.removeItem("profesional");
      localStorage.setItem("profesional", JSON.stringify(profesional));
    }
    this.formulario.patchValue({
      id,
      nombre,
      apellido,
      especialidades,
      estado,
    });
  }
}
