import { Component, OnInit, AfterContentInit, DoCheck } from "@angular/core";
import { UserService } from "../../services/users.service";

@Component({
  selector: "app-tabs",
  templateUrl: "./tabs.page.html",
  styleUrls: ["./tabs.page.scss"],
  providers: [UserService],
})
export class TabsPage implements OnInit, AfterContentInit, DoCheck {
  isLogged = false;
  stateToken = false;

  constructor(public userservice: UserService) {}

  ngOnInit() {
    // is logged
    this.userservice.isLogged.subscribe((res) => {
      this.isLogged = res;
    });
    setTimeout(() => {
      if (localStorage.getItem("token") !== null) {
        localStorage.setItem("tabLogged", JSON.stringify(true));
        //this.stateToken = true;
      } else if (localStorage.getItem("token") === null) {
        localStorage.setItem("tabLogged", JSON.stringify(false));
      }
    }, 1000);
  }

  ngDoCheck() {
    if (localStorage.getItem("tabLogged") !== null) {
      this.stateToken = JSON.parse(localStorage.getItem("tabLogged"));
      setTimeout(() => {
        localStorage.removeItem("tabLogged");
      }, 200);
    }
  }
  ngAfterContentInit() {
  }
}
