import { Component, OnInit, AfterContentInit, DoCheck } from "@angular/core";
import { MenuController } from "@ionic/angular";
import { Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { UserService } from "./services/users.service";
//router
import { Router, ActivatedRoute, Params } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  providers: [UserService],
})
export class AppComponent implements OnInit, AfterContentInit, DoCheck {
  isAdmin = false;
  isLogged = false;
  load = true;
  stateToken = false;
  userId;
  username;
  bool: boolean = false;
  googleImg: string;
  public messageText: string;
  public user: string;
  public room: string;
  public localtime: string;
  public time;
  public hours;
  public msg;
  public messageArray: Array<{ user: string; message: string }> = [];
  defaultImg: string;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private menu: MenuController,
    public userservice: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.initializeApp();
  }

  ngOnInit() {
    if (localStorage.getItem("user_client") !== null) {
      let user = JSON.parse(localStorage.getItem("user_client"));

      this.userId = user.userId;
      this.username = user.username;
      this.defaultImg = "../../../assets/img/logo_clinica.jpg";
    } else if (localStorage.getItem("google") !== null) {
      let user = JSON.parse(localStorage.getItem("google"));

      //this.userId = user.userId;
      this.username = user.email;
      this.googleImg = user.picture;
      this.bool = true;
    }
    // is logged
    const token = localStorage.getItem("token");
    this.userservice.isLogged.subscribe((res) => {
      this.isLogged = res;
      setTimeout(() => {
        this.load = false;
      }, 200);
    });

    // validar si es el admin

    this.defaultImg = "../../../assets/img/logo_clinica.jpg";
    this.getCurrentDate();
    this.decide();
    // isAdmin
    // is admin
    // this.userservice.
  }

  ngDoCheck() {
    if (localStorage.getItem("tabLogged") !== null) {
      this.stateToken = JSON.parse(localStorage.getItem("tabLogged"));
      setTimeout(() => {
        localStorage.removeItem("tabLogged");
      }, 200);
    }
  }
  ngAfterContentInit() {}

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  openFirst() {
    this.menu.enable(true, "first");
    this.menu.open("first");
  }

  openEnd() {
    this.menu.open("end");
  }

  openCustom() {
    this.menu.enable(true, "custom");
    this.menu.open("custom");
  }

  onLogout() {
    this.userservice.logout();
    // remove sesion id contacto from user
    localStorage.removeItem("id_contacto");
    localStorage.removeItem("user_client");
    localStorage.removeItem("google");
    localStorage.removeItem("token");
    localStorage.removeItem("archivos");
    localStorage.removeItem("apoderados");
    localStorage.removeItem("cargas");
    localStorage.removeItem("paciente");
    localStorage.removeItem("pagos");
    localStorage.removeItem("convenios");
    localStorage.removeItem("devoluciones");
    localStorage.removeItem("evoluciones");
    localStorage.removeItem("adicionales");
    localStorage.removeItem("tratamiento");
    localStorage.removeItem("cita");
    localStorage.removeItem("notificacionesGes");
    localStorage.removeItem("recetas");
    localStorage.removeItem("tareas");
    setTimeout(() => {
      this.router.navigate(["login"]);
    }, 200);
  }

  getCurrentDate() {
    setInterval(() => {
      this.time = new Date(); //set time variable with current date
    }, 1000); // set it every one seconds
  }

  decide() {
    this.hours = new Date().getHours();
    //console.log({ Hora: this.hours });
    if (this.hours > 6 && this.hours < 12) {
      this.msg = "Buenos Días";
    } else if (this.hours >= 12 && this.hours < 20) {
      this.msg = "Buenas Tardes";
    } else if (this.hours >= 20 && this.hours < 23) {
      this.msg = "Buenas Noches";
    } else if (this.hours >= 23 ) {
      this.msg = "Madrugada";
    } else if (this.hours <= 6 ) {
      this.msg = "Dulces sueños!";
    }
  }
}
