import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
//enviroment
import { environment } from "../../environments/environment";
//formulario
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";

//lodash
import * as _ from "lodash";

/** RXJS */
import { Observable, of, from, Subject, forkJoin, BehaviorSubject } from "rxjs";
import { tap, map, mergeMap, take } from "rxjs/operators";
import { ajax } from "rxjs/ajax";

@Injectable({
  providedIn: "root",
})
export class CitasService {
  constructor(private http: HttpClient, private _formBuilder: FormBuilder) {}

  public url: string = environment.production.serviceUrl;
  private settings = environment.production.settings;

  subject: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
  array$: Observable<any> = this.subject.asObservable();
  //FORMULARIO RUT, ESPECIALIDAD Y PROFESIONAL
  rutFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  getCitas(): Observable<any> {

    return this.http
      .get(this.url + `citas`, this.settings)
      .pipe(map((result: Response) => result));

  }

  getDentistById(id: number): Observable<any> {
    return this.http
      .get(this.url + `dentistas/${id}`, this.settings)
      .pipe(map((result: Response) => result));
  }

  getEstadoById(id: number): Observable<any> {
    return this.http
      .get(this.url + `citas/estados/${id}`, this.settings)
      .pipe(map((result: Response) => result));
  }

  getSucursalById(id: number): Observable<any> {
    return this.http
      .get(this.url + `sucursales/${id}`, this.settings)
      .pipe(map((result: Response) => result));
  }

  getTreatmentById(id: number): Observable<any> {
    return this.http
      .get(this.url + `tratamientos/${id}`, this.settings)
      .pipe(map((result: Response) => result));
  }

  getPacienteById(id: number): Observable<any> {
    return this.http
      .get(this.url + `pacientes/${id}`, this.settings)
      .pipe(map((result: Response) => result));
  }

  // FORMULARIO
  formulario: FormGroup = new FormGroup({
    id: new FormControl(null),
    nombre: new FormControl("", Validators.required),
    apellido: new FormControl("", Validators.required),
    especialidades: new FormControl("", Validators.required),
    estado: new FormControl(true, Validators.required),
  });

  initializeFormGroup() {
    this.formulario.setValue({
      id: null,
      nombre: "",
      apellido: "",
      especialidades: "",
      estado: true,
    });
  }

  //FORMULARIO ESPECIALIDAD Y PROFESIONAL VALIDATION
  rutFormService() {
    this.rutFormGroup = this._formBuilder.group({
      rutuser: new FormControl("", [
        Validators.required,
        Validators.minLength(9),
      ]),
    });
  }
  //FORMULARIO ESPECIALIDAD Y PROFESIONAL VALIDATION
  secondFormStep() {
    this.secondFormGroup = this._formBuilder.group({
      especialidad: ["", Validators.required],
      profesional: ["", Validators.required],
    });
  }

  insertProfesional(profesional) {
  }

  updateProfesional(profesional) {
  }

  //DELETE
  deleteProducto(id: Number) {
    //this.contactList.remove(id);
  }

  populateForm(profesional) {
    const { id, nombre, apellido, especialidades, estado } = profesional;

    if (localStorage.getItem("profesional") === null) {
      localStorage.setItem("profesional", JSON.stringify(profesional));
    } else {
      localStorage.removeItem("profesional");
      localStorage.setItem("profesional", JSON.stringify(profesional));
    }
    this.formulario.patchValue({
      id,
      nombre,
      apellido,
      especialidades,
      estado,
    });
  }

  addElementToObservableArray(item) {
    this.array$.pipe(take(1)).subscribe((val) => {
      const newArr = [...val, item];
      this.subject.next(newArr);
    });
  }
}
