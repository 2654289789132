// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  develop: {
    prod: true,
    API_URL: "https://roadent.cl",
    serviceUrl: "https://api.dentalink.healthatom.com/api/v1/",
    apiKey:
      "blVsgEn01SxP2ygfnrL535LHa0yQ2z99nwOpAFNe.MAOzf4qvW6cKz9TA5HIlHvsdkyIwsc9KgraGtWQX",
    settings: {
      crossDomain: true,
      method: "GET",
      headers: {
        Authorization:
          "Token " +
          "blVsgEn01SxP2ygfnrL535LHa0yQ2z99nwOpAFNe.MAOzf4qvW6cKz9TA5HIlHvsdkyIwsc9KgraGtWQX",
      },
    },
    firebaseConfig: {
      apiKey: "AIzaSyA1H_CXgrxzABbGHIjpuVJflexRsFAgU-Y",
      authDomain: "amcrud-6d7f9.firebaseapp.com",
      databaseURL: "https://amcrud-6d7f9.firebaseio.com",
      projectId: "amcrud-6d7f9",
      storageBucket: "amcrud-6d7f9.appspot.com",
      messagingSenderId: "1063131922521",
      appId: "1:1063131922521:web:9e98fe3ae2dbfabdd0b148",
      measurementId: "G-TR43CQ96GV",
    },
  },
  production: {
    prod: true,
    API_URL: "https://roadent.cl",
    serviceUrl: "https://api.dentalink.healthatom.com/api/v1/",
    apiKey:
      "blVsgEn01SxP2ygfnrL535LHa0yQ2z99nwOpAFNe.MAOzf4qvW6cKz9TA5HIlHvsdkyIwsc9KgraGtWQX",
    settings: {
      crossDomain: true,
      method: "GET",
      headers: {
        Authorization:
          "Token " +
          "blVsgEn01SxP2ygfnrL535LHa0yQ2z99nwOpAFNe.MAOzf4qvW6cKz9TA5HIlHvsdkyIwsc9KgraGtWQX",
      },
    },
    firebaseConfig: {
      apiKey: "AIzaSyA1H_CXgrxzABbGHIjpuVJflexRsFAgU-Y",
      authDomain: "amcrud-6d7f9.firebaseapp.com",
      databaseURL: "https://amcrud-6d7f9.firebaseio.com",
      projectId: "amcrud-6d7f9",
      storageBucket: "amcrud-6d7f9.appspot.com",
      messagingSenderId: "1063131922521",
      appId: "1:1063131922521:web:9e98fe3ae2dbfabdd0b148",
      measurementId: "G-TR43CQ96GV",
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
