import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { UserService } from "../services/users.service";
import { map, take } from "rxjs/operators";
@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  constructor(public userservice: UserService) {}
  canActivate(): Observable<boolean> {
    return this.userservice.isLogged.pipe(
      take(1),
      map((isLogged: boolean) => isLogged)
    );
  }
}
