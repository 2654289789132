import { NgModule } from "@angular/core";

//import { BreadcrumbsComponent } from "./breadcrumbs/breadcrumbs.component";
import { MainNavComponent } from "./main-nav/main-nav.component";
import { MatComfirmDialogComponent } from "./mat-comfirm-dialog/mat-comfirm-dialog.component";
import { BreadcrumbsComponent } from "./breadcrumbs/breadcrumbs.component";

@NgModule({
  declarations: [
    MainNavComponent,
    MatComfirmDialogComponent,
    BreadcrumbsComponent,
  ],
  exports: [MainNavComponent, MatComfirmDialogComponent, BreadcrumbsComponent],
})
export class ComponentsModule {}
