import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";

import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

// import la clase httpclinet PARA LOS SERVICIOS REST
import { HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
//FIREBASE ENVIRONMENT CONFIG
import { environment } from "../environments/environment";

//FIREBASE CLASES
import { AngularFireModule } from "angularfire2";
import { AngularFireDatabaseModule } from "angularfire2/database";
import { AngularFireAuthModule } from "angularfire2/auth";

// ANGULAR MATERIAL
import { AppMaterialModule } from "./app-material.module";
//MODAL
import { MatDialogModule } from "@angular/material/dialog";
//COMPONENTES
import { ComponentsModule } from "./components/components.module";

import { TabsPageModule } from "./pages/tabs/tabs.module";

import { TabsPage } from "./pages/tabs/tabs.page";
import { ServiceWorkerModule } from '@angular/service-worker';

@NgModule({
  declarations: [AppComponent, TabsPage],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      rippleEffect: false,
      mode: "md",
    }),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    MatDialogModule,
    ComponentsModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    AngularFireModule.initializeApp(environment.production.firebaseConfig),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,//environment.production.prod,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
