import { Component, OnInit, OnDestroy } from "@angular/core";
//SERVICIO
import { ProfesionalService } from "../../../services/profesional.service";
//ROUTER
import { Router, ActivatedRoute, Params } from "@angular/router";

//MODELO
//import { Citas } from "../../../model/Citas.model";
//SERVICIO
import { PacientesService } from "../../../services/pacientes.service";

@Component({
  selector: "app-pacient-details",
  templateUrl: "./pacient-details.component.html",
  styleUrls: ["./pacient-details.component.scss"],
  providers: [PacientesService],
})
export class PacientDetailsComponent implements OnInit {
  public dentista: any;
  public estado: any;
  public paciente: any;
  public clinica: any;
  public tratamientos: Array<any>;
  public citas: Array<any>;
  public notificacionesGes: Array<any>;
  public apoderados: Array<any>;
  public archivos: Array<any>;
  public cargas: Array<any>;
  public convenios: Array<any>;
  public devoluciones: Array<any>;
  public evoluciones: Array<any>;
  public adicionales: Array<any>;
  public recetas: Array<any>;
  public tareas: Array<any>;
  public pagos: Array<any>;
  public typeOfService: string;
  public load = false;
  public dataIsExist = false;
  public linksIsExist = false;
  public links: Array<{ rel: String; href: String; method: string }>;
  buttonBack = "";

  constructor(
    public profesionalservice: ProfesionalService,
    public pacienteservice: PacientesService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this._route.params.subscribe((params) => {
      const routeId = params["id"]; //Number();
      const service = params["service"];
      if (service === undefined) {
      }
      this.typeOfService = service;
      switch (service) {
        case undefined:
          this.pacienteservice
            .getPacienteById(routeId)
            .subscribe(async (res) => {
              const payload = await res.data;
              if (res !== undefined) {
                this.validateIsPacientDataExist(res);
                this.PacienteLocalStorage(payload);
              }
            });
          break;
        case "apoderados":
          this.pacienteservice
            .getApoderadosById(service, routeId)
            .subscribe(async (res) => {
              const payload = await res.data;
              if (res !== undefined) {
                this.validateIsDataExist(res);
                this.ApoderadosLocalStorage(payload);
              }
            });

          break;

        case "archivos":
          this.pacienteservice
            .getArchivosById(service, routeId)
            .subscribe(async (res) => {
              const payload = await res.data;
              if (res !== undefined) {
                this.validateIsDataExist(res);
                this.ArchivosLocalStorage(payload);
              }
            });
          break;

        case "cargas":
          this.pacienteservice
            .getCargasById(service, routeId)
            .subscribe(async (res) => {
              const payload = await res.data;
              if (res !== undefined) {
                this.validateIsDataExist(res);
                this.CargasLocalStorage(payload);
              }
            });
          break;
        case "citas":
          this.pacienteservice
            .getCitasById(service, routeId)
            .subscribe(async (res: any) => {
              const payload = await res.data;
              if (res !== undefined) {
                this.validateIsDataExist(res);
                this.CitasLocalStorage(payload);
              }
            });
          break;
        case "convenios":
          this.pacienteservice
            .getConveniosById(service, routeId)
            .subscribe(async (res) => {
              const payload = await res.data;
              if (res !== undefined) {
                this.validateIsDataExist(res);
                this.ConveniosLocalStorage(payload);
              }
            });
          break;
        case "devoluciones":
          this.pacienteservice
            .getDevolucionesById(service, routeId)
            .subscribe(async (res) => {
              const payload = await res.data;
              if (res !== undefined) {
                this.validateIsDataExist(res);
                this.DevolucionesLocalStorage(payload);
              }
            });
          break;
        case "evoluciones":
          this.pacienteservice
            .getEvolucionesById(service, routeId)
            .subscribe(async (res) => {
              const payload = await res.data;
              if (res !== undefined) {
                this.validateIsDataExist(res);
                this.EvolucionesLocalStorage(payload);
              }
            });
          break;
        case "pagos":
          this.pacienteservice
            .getPagosById(service, routeId)
            .subscribe(async (res) => {
              const payload = await res.data;
              if (res !== undefined) {
                this.validateIsDataExist(res);
                this.PagosLocalStorage(payload);
              }
            });
          break;
        case "tratamientos":
          this.pacienteservice
            .getTratamientosById(service, routeId)
            .subscribe(async (res) => {
              const payload = await res.data;
              if (res !== undefined) {
                this.validateIsDataExist(res);
                this.TratamientoLocalStorage(payload);
              }
            });
          break;
        case "adicionales":
          this.pacienteservice
            .getAdicionalesById(service, routeId)
            .subscribe(async (res) => {
              const payload = await res.data;
              if (res !== undefined) {
                this.validateIsDataExist(res);
                this.AdicionalesLocalStorage(payload);
              }
            });
          break;
        case "tareas":
          this.pacienteservice
            .getTareasById(service, routeId)
            .subscribe(async (res) => {
              const payload = await res.data;
              if (res !== undefined) {
                this.validateIsDataExist(res);
                this.TareasLocalStorage(payload);
              }
            });
          break;
        case "recetas":
          this.pacienteservice
            .getRecetasById(service, routeId)
            .subscribe(async (res) => {
              const payload = await res.data;
              if (res !== undefined) {
                this.validateIsDataExist(res);
                this.RecetasLocalStorage(payload);
              }
            });
          break;
        case "notificacionesGes":
          this.pacienteservice
            .getNotificacionesGesById(service, routeId)
            .subscribe(async (res) => {
              const payload = await res.data;
              if (res !== undefined) {
                this.validateIsDataExist(res);
                this.NotificacionesLocalStorage(payload);
              }
            });
          break;
        default:
          break;
      }
    });
  }

  ngOnInit() {}

  DentistasLocalStorage(data: any) {
    if (localStorage.getItem("dentista") === null) {
      localStorage.setItem("dentista", JSON.stringify(data));
      this.dentista = JSON.parse(localStorage.getItem("dentista"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    } else {
      localStorage.removeItem("dentista");
      localStorage.setItem("dentista", JSON.stringify(data));
      this.dentista = JSON.parse(localStorage.getItem("dentista"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    }
    this.links = this.dentista.links;
  }

  EstadoCitaLocalStorage(data: any) {
    if (localStorage.getItem("estado_cita") === null) {
      localStorage.setItem("estado_cita", JSON.stringify(data));
      this.estado = JSON.parse(localStorage.getItem("estado_cita"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    } else {
      localStorage.removeItem("estado_cita");
      localStorage.setItem("estado_cita", JSON.stringify(data));
      this.estado = JSON.parse(localStorage.getItem("estado_cita"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    }
    this.links = this.estado.links;
  }

  PacienteLocalStorage(data: any) {
    if (localStorage.getItem("paciente") === null) {
      localStorage.setItem("paciente", JSON.stringify(data));
      this.paciente = JSON.parse(localStorage.getItem("paciente"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    } else {
      localStorage.removeItem("paciente");
      localStorage.setItem("paciente", JSON.stringify(data));
      this.paciente = JSON.parse(localStorage.getItem("paciente"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    }
    this.links = this.paciente.links;
  }
  ClinicaLocalStorage(data: any) {
    if (localStorage.getItem("sucursal") === null) {
      localStorage.setItem("sucursal", JSON.stringify(data));
      this.clinica = JSON.parse(localStorage.getItem("sucursal"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    } else {
      localStorage.removeItem("sucursal");
      localStorage.setItem("sucursal", JSON.stringify(data));
      this.clinica = JSON.parse(localStorage.getItem("sucursal"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    }
    this.links = this.clinica.links;
  }

  TratamientoLocalStorage(data: any) {
    if (localStorage.getItem("tratamiento") === null) {
      localStorage.setItem("tratamiento", JSON.stringify(data));
      this.tratamientos = JSON.parse(localStorage.getItem("tratamiento"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    } else {
      localStorage.removeItem("tratamiento");
      localStorage.setItem("tratamiento", JSON.stringify(data));
      this.tratamientos = JSON.parse(localStorage.getItem("tratamiento"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    }
  }

  CitasLocalStorage(data: any) {
    if (localStorage.getItem("cita") === null) {
      localStorage.setItem("cita", JSON.stringify(data));
      this.citas = JSON.parse(localStorage.getItem("cita"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    } else {
      localStorage.removeItem("cita");
      localStorage.setItem("cita", JSON.stringify(data));
      this.citas = JSON.parse(localStorage.getItem("cita"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    }
  }

  NotificacionesLocalStorage(data: any) {
    if (localStorage.getItem("notificacionesGes") === null) {
      localStorage.setItem("notificacionesGes", JSON.stringify(data));
      this.notificacionesGes = JSON.parse(localStorage.getItem("cita"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    } else {
      localStorage.removeItem("notificacionesGes");
      localStorage.setItem("notificacionesGes", JSON.stringify(data));
      this.notificacionesGes = JSON.parse(
        localStorage.getItem("notificacionesGes")
      );
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    }
  }

  RecetasLocalStorage(data: any) {
    if (localStorage.getItem("recetas") === null) {
      localStorage.setItem("recetas", JSON.stringify(data));
      this.recetas = JSON.parse(localStorage.getItem("recetas"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    } else {
      localStorage.removeItem("recetas");
      localStorage.setItem("recetas", JSON.stringify(data));
      this.recetas = JSON.parse(localStorage.getItem("recetas"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    }
  }

  TareasLocalStorage(data: any) {
    if (localStorage.getItem("tareas") === null) {
      localStorage.setItem("tareas", JSON.stringify(data));
      this.tareas = JSON.parse(localStorage.getItem("tareas"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    } else {
      localStorage.removeItem("tareas");
      localStorage.setItem("tareas", JSON.stringify(data));
      this.tareas = JSON.parse(localStorage.getItem("tareas"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    }
  }

  PagosLocalStorage(data: any) {
    if (localStorage.getItem("pagos") === null) {
      localStorage.setItem("pagos", JSON.stringify(data));
      this.pagos = JSON.parse(localStorage.getItem("pagos"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    } else {
      localStorage.removeItem("pagos");
      localStorage.setItem("pagos", JSON.stringify(data));
      this.pagos = JSON.parse(localStorage.getItem("pagos"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    }
  }

  ConveniosLocalStorage(data: any) {
    if (localStorage.getItem("convenios") === null) {
      localStorage.setItem("convenios", JSON.stringify(data));
      this.convenios = JSON.parse(localStorage.getItem("convenios"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    } else {
      localStorage.removeItem("convenios");
      localStorage.setItem("convenios", JSON.stringify(data));
      this.convenios = JSON.parse(localStorage.getItem("convenios"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    }
  }

  DevolucionesLocalStorage(data: any) {
    if (localStorage.getItem("devoluciones") === null) {
      localStorage.setItem("devoluciones", JSON.stringify(data));
      this.devoluciones = JSON.parse(localStorage.getItem("devoluciones"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    } else {
      localStorage.removeItem("devoluciones");
      localStorage.setItem("devoluciones", JSON.stringify(data));
      this.devoluciones = JSON.parse(localStorage.getItem("devoluciones"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    }
  }

  EvolucionesLocalStorage(data: any) {
    if (localStorage.getItem("evoluciones") === null) {
      localStorage.setItem("evoluciones", JSON.stringify(data));
      this.evoluciones = JSON.parse(localStorage.getItem("evoluciones"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    } else {
      localStorage.removeItem("evoluciones");
      localStorage.setItem("evoluciones", JSON.stringify(data));
      this.evoluciones = JSON.parse(localStorage.getItem("evoluciones"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    }
  }

  AdicionalesLocalStorage(data: any) {
    if (localStorage.getItem("adicionales") === null) {
      localStorage.setItem("adicionales", JSON.stringify(data));
      this.adicionales = JSON.parse(localStorage.getItem("adicionales"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    } else {
      localStorage.removeItem("adicionales");
      localStorage.setItem("adicionales", JSON.stringify(data));
      this.adicionales = JSON.parse(localStorage.getItem("adicionales"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    }
  }

  ArchivosLocalStorage(data: any) {
    if (localStorage.getItem("archivos") === null) {
      localStorage.setItem("archivos", JSON.stringify(data));
      this.archivos = JSON.parse(localStorage.getItem("archivos"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    } else {
      localStorage.removeItem("archivos");
      localStorage.setItem("archivos", JSON.stringify(data));
      this.archivos = JSON.parse(localStorage.getItem("archivos"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    }
  }
  ApoderadosLocalStorage(data: any) {
    if (localStorage.getItem("apoderados") === null) {
      localStorage.setItem("apoderados", JSON.stringify(data));
      this.apoderados = JSON.parse(localStorage.getItem("apoderados"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    } else {
      localStorage.removeItem("apoderados");
      localStorage.setItem("apoderados", JSON.stringify(data));
      this.apoderados = JSON.parse(localStorage.getItem("apoderados"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    }
  }

  CargasLocalStorage(data: any) {
    if (localStorage.getItem("cargas") === null) {
      localStorage.setItem("cargas", JSON.stringify(data));
      this.cargas = JSON.parse(localStorage.getItem("cargas"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    } else {
      localStorage.removeItem("cargas");
      localStorage.setItem("cargas", JSON.stringify(data));
      this.cargas = JSON.parse(localStorage.getItem("cargas"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    }
  }

  ngOnDestroy() {
    localStorage.removeItem("archivos");
    localStorage.removeItem("apoderados");
    localStorage.removeItem("cargas");
    localStorage.removeItem("paciente");
    localStorage.removeItem("pagos");
    localStorage.removeItem("convenios");
    localStorage.removeItem("devoluciones");
    localStorage.removeItem("evoluciones");
    localStorage.removeItem("adicionales");
    localStorage.removeItem("tratamiento");
    localStorage.removeItem("cita");
    localStorage.removeItem("notificacionesGes");
    localStorage.removeItem("recetas");
  }

  validateIsDataExist(payload: any) {
    if (payload.data.length !== undefined) {
      const dataExist = payload.data.length >= 1 ? true : false;
      this.dataIsExist = dataExist;
    }
  }

  validateIsPacientDataExist(payload: any) {
    if (payload.data !== undefined) {
      this.linksIsExist = false;

      this.dataIsExist = true;
    }
  }
}
