import { Component, OnInit } from "@angular/core";
//ROUTER
import {
  Router,
  ActivatedRoute,
  Params,
  NavigationEnd,
  RoutesRecognized,
} from "@angular/router";
import { StateService } from "./../../state.service";
import { Observable } from "rxjs";
//SERVICIO
import { BreadCrumbService } from "../../services/breadcrumb.service";
//MODELO
import { Breadcrumb } from "../../model/breadcrumb.model";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

@Component({
  selector: "app-breadcrumbs",
  templateUrl: "./breadcrumbs.component.html",
  styleUrls: ["./breadcrumbs.component.css"],
  providers: [BreadCrumbService, StateService],
})
export class BreadcrumbsComponent implements OnInit {
  //public profile: Profile;

  // rxjs
  public breads$: Observable<any>;
  public name$: Observable<any>;
  public breadcrumbs;
  public breads: Breadcrumb;
  public baseurl;
  public currentUrl: string;

  constructor(
    //public profileService: ProfileService,
    public stateservice: StateService,
    public breadscrumbservice: BreadCrumbService,
    private _route: ActivatedRoute,
    private _router: Router,
    private breakpointObserver: BreakpointObserver,
    private router: Router
  ) {
    //this.baseUrl();
  }

  ngOnInit(): void {
    this.router.events.subscribe((_: NavigationEnd) => {
      if (_.url !== "undefined") {
        this.HandleUrl(_.url);
      }
    });

  }

  HandleUrl(url) {
    if (url !== "undefined") {
      //this.currentUrl = _.url;
      this.currentUrl = url;

      let location = window.location.href;
      const bool = this.currentUrl === "undefined" ? location : url;
      let result = location.match(bool).toString();

      if (localStorage.getItem("breadcrumb") === null) {
        localStorage.setItem("breadcrumb", result);
      } else {
        localStorage.removeItem("breadcrumb");
        localStorage.setItem("breadcrumb", result);
      }

    }
  }
  baseUrl() {
    this.baseurl = window.location.href;
  }
}
