import { Injectable } from "@angular/core";
// httpClient
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";

//enviroment
import { environment } from "../../environments/environment";
// servicio contacto

import { NotificationService } from "./notification.service";
//modelo
import { User, UserLogin, UserResponse } from "../model/user.model";
// rxjs
import { Observable, throwError, BehaviorSubject, Subscription } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ajax } from "rxjs/ajax";

//import "rxjs/Rx";

// auth 0
import { JwtHelperService } from "@auth0/angular-jwt";

//router
import { Router, ActivatedRoute, Params } from "@angular/router";

//formulario
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";

//lodash
import * as _ from "lodash";

// FIREBASE
import { AngularFireAuth } from "angularfire2/auth";
import * as firebase from "firebase/app";
import * as functions from "firebase/functions";


// CRUD FIREBASE OPERATION 
import { AngularFireDatabase, AngularFireList } from "angularfire2/database";

const helper = new JwtHelperService();

@Injectable({
  providedIn: "root",
})
export class UserService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  public userState: Observable<firebase.User>;
  googleUser: any;
  public usersList: AngularFireList<any>;
  public apiUrl: string = environment.production.API_URL;
  public url: string = environment.production.serviceUrl;
  private settings = environment.production.settings;
  constructor(
    private firebase: AngularFireDatabase,
    public notificationService: NotificationService,
    private http: HttpClient,
    private afAuth: AngularFireAuth,
    private router: Router
  ) {
    this.checkToken();
    this.userState = afAuth.authState;
  }

  public array = [];
  // getters
  get isLogged(): Observable<boolean> {
    return this.loggedIn.asObservable();
  }
  getPacientesByFilters(rut: string): Observable<any> {
    var query_string = `?q={"rut":{"lk":"${rut}"}}`;

    return this.http
      .get(this.url + `pacientes` + query_string, this.settings)
      .pipe(map((result: Response) => result));
  }
  getData(id: number) {
    const gh = ajax.getJSON(this.url + `profesional/${id}`);

    const data$ = new Observable((observer) => {
      gh.subscribe(
        (res) => {
          observer.next(res);
          observer.complete();
        },
        (err) => {
          observer.error(err);
        }
      );
    });
    return data$;
  }

  getUsersFireList(): Observable<any> {
    this.usersList = this.firebase.list("users");

    return this.usersList.snapshotChanges(); // me retorna un observable de la base de datos firebase
  }

  getUsersGoogle(token: any) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
      auth: token,
    });
    return this.http.get<any>(`${environment.production.API_URL}/` + `users`, {
      headers: headers,
    });
  }

  // FORMULARIO
  formulario: FormGroup = new FormGroup({
    id: new FormControl(null),
    username: new FormControl("", Validators.required),
  });

  loginForm: FormGroup = new FormGroup({
    username: new FormControl("", Validators.required),
  });

  initializeFormGroup() {
    this.formulario.setValue({
      id: null,
      username: "",
    });
  }

  populateForm(user) {
    const { id, username, password, role } = user;

    if (localStorage.getItem("usuario") === null) {
      localStorage.setItem("usuario", JSON.stringify(user));
    } else {
      localStorage.removeItem("usuario");
      localStorage.setItem("usuario", JSON.stringify(user));
    }

    this.formulario.patchValue({
      id,
      username,
      password,
      role,
    });
  }

  populateEditForm(user) {
    const { id, username, role } = user;

    if (localStorage.getItem("usuario") === null) {
      localStorage.setItem("usuario", JSON.stringify({ id, username, role }));
    } else {
      localStorage.removeItem("usuario");
      localStorage.setItem("usuario", JSON.stringify({ id, username, role }));
    }
  }

  logintUser(user) {
    const headers = new HttpHeaders({
      "Content-Type": "application/json",
    });

    const { username } = user;
    return this.http.post<any>(
      `${environment.production.API_URL}/auth/login`,
      { username },
      {
        headers: headers,
      }
    );
  }

  logout(): void {
    localStorage.removeItem("token");
    localStorage.setItem("tabLogged", JSON.stringify(false));
    this.loggedIn.next(false);
  }

  login(authData: UserLogin): Observable<UserResponse | void> {
    return this.http
      .post<UserResponse>(
        `${environment.production.API_URL}/auth/login`,
        authData
      )
      .pipe(
        map((authResponse: any) => {
          if (authResponse.status) {
            this.saveToken(authResponse.token);
            this.saveUserLocalStorage(authResponse.data);
            this.loggedIn.next(true);
          }

          return authResponse;
        }),
        catchError((err) => this.handleError(err))
      );
  }

  private checkToken(): void {
    const userToken = localStorage.getItem("token");
    const isExpired = helper.isTokenExpired(userToken); //helper
    isExpired ? this.logout() : this.loggedIn.next(true);
  }
  private saveToken(token: string): void {
    localStorage.setItem("token", token);
  }
  private handleError(err): Observable<never> {
    let errorMessage = "mensaje de error ";
    if (err) {
      errorMessage = `Error: code ${err.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }

  getUserById(id: number) {
    /*const headers = new HttpHeaders({
      "Content-Type": "application/json",
      auth: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOjE2LCJ1c2VybmFtZSI6ImNocmlzbG9AZ21haWwuY29tIiwiaWF0IjoxNjA5ODY5NzM0LCJleHAiOjE2MDk5MTI5MzR9.H3VYLDgr4i8n6feIbv7KoyD8UeuW3nRZCQ-dnd4aMys",
    });

    return this.http.get<any>(this.url + `users/${id}`, {
      headers: headers,
    });*/
  }

  // ME RETORNA EL OBJETO DE FIREBASE
  async GoogleAuth() {
    try {
      // accion de logear me retornara una PROMESA
      // LOGIN FIREBASE OBSERVABLE
      return this.afAuth.auth
        .signInWithPopup(new firebase.auth.GoogleAuthProvider())

        .then(async (data) => {
          //console.log(data.additionalUserInfo.profile);
        // console.log(data);
          const headers = new HttpHeaders({
            "Content-Type": "application/json",
          });

          let userData = await this.localStorageGoogleAuth(data);

          const { id, email, given_name, family_name } = userData; // get data

          // GOOGLE NODEJS CALL
          // OBTENER EL TOKEN DE LA APLICACION EN NODEJS
          try {
            this.http
              .post<any>(
                this.apiUrl + "/auth/google",
                { id, email, given_name, family_name },
                {
                  headers: headers,
                }
              )
              .subscribe((response) => {
                const token = this.localStorageSaveTokenAuth(response); // GUARDA EL TOKEN DE LA APP Y CAMBIA EL ESTADO DE LA TAB
                this.loggedIn.next(true);
                this.notificationService.success(":: Bienvenido !!! 💕 🧐 🥳");

                this.router.navigate(["profile"]);
              });
          } catch (error) {
            console.log(error);
          }
        })
        .catch((error) => {
          // this.notificationService.warn("Error!");

          console.log(error);
          return;
        });
    } catch (error) {
      console.log(error);
      return;
    }
  }
  saveUserLocalStorage(user: any) {
    // { userId: user.userId, username: user.username }
    if (localStorage.getItem("user_client") === null) {
      localStorage.setItem("user_client", JSON.stringify(user));
    } else {
      localStorage.removeItem("user_client");
      localStorage.setItem("user_client", JSON.stringify(user));
    }
  }
  async localStorageGoogleAuth(data: any) {
    // localstorage google object
    let userData;
    if (data.credential) {
      if (localStorage.getItem("google") === null) {
        localStorage.setItem(
          "google",
          JSON.stringify(data.additionalUserInfo.profile)
        );
        userData = JSON.parse(localStorage.getItem("google"));
      } else {
        localStorage.removeItem("google");
        localStorage.setItem(
          "google",
          JSON.stringify(data.additionalUserInfo.profile)
        );
        userData = JSON.parse(localStorage.getItem("google"));
      }
      //this.googleUser = data.additionalUserInfo.profile;
    }
    return userData;
  }

  localStorageSaveTokenAuth(response: any) {
    let token;
    if (localStorage.getItem("token") === null) {
      localStorage.setItem("token", JSON.stringify(response.token));
      token = JSON.parse(localStorage.getItem("token"));
    } else {
      localStorage.removeItem("token");
      localStorage.setItem("token", JSON.stringify(response.token));
      token = JSON.parse(localStorage.getItem("token"));
    }

    // localStorage User is Logged
    localStorage.setItem("tabLogged", JSON.stringify(true));
    return token;
  }
}
