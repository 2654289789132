import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
//enviroment
import { environment } from "../../environments/environment";
//formulario
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";

//lodash
import * as _ from "lodash";
/** RXJS */
import { Observable, of, from, Subject, forkJoin, BehaviorSubject } from "rxjs";
import { tap, map, mergeMap, take } from "rxjs/operators";
import { ajax } from "rxjs/ajax";

@Injectable({
  providedIn: "root",
})
export class HorarioService {
  constructor(
    private http: HttpClient,
    private _formBuilder: FormBuilder // public socketservice: WebSocket
  ) {}
  private id_profesional: number;
  private id_especialidad: number;
  private id_contacto: number;
  public url: string = environment.production.serviceUrl;
  private settings = environment.production.settings;

  subject: BehaviorSubject<any[]> = new BehaviorSubject<any>([]);
  array$: Observable<any> = this.subject.asObservable();
  public arrHours: { id: number; hora: string; minutes: string }[] = [
    /*  { id: 1, hora: "00", minutes: "00" },
    { id: 1, hora: "00", minutes: "30" },
    { id: 1, hora: "01", minutes: "00" },
    { id: 1, hora: "01", minutes: "30" },
    { id: 1, hora: "02", minutes: "00" },
    { id: 1, hora: "02", minutes: "30" },
    { id: 1, hora: "03", minutes: "00" },
    { id: 1, hora: "03", minutes: "30" },
    { id: 1, hora: "04", minutes: "00" },
    { id: 1, hora: "04", minutes: "30" },
    { id: 1, hora: "05", minutes: "00" },
    { id: 1, hora: "05", minutes: "30" },
    { id: 1, hora: "06", minutes: "00" },
    { id: 1, hora: "06", minutes: "30" },
    { id: 1, hora: "07", minutes: "00" },
    { id: 1, hora: "07", minutes: "30" },
    { id: 1, hora: "08", minutes: "00" },*/
    { id: 1, hora: "08", minutes: "30" },
    { id: 1, hora: "09", minutes: "00" },
    { id: 1, hora: "09", minutes: "30" },
    { id: 1, hora: "10", minutes: "00" },
    { id: 1, hora: "10", minutes: "30" },
    { id: 1, hora: "11", minutes: "00" },
    { id: 1, hora: "11", minutes: "30" },
    { id: 1, hora: "12", minutes: "00" },
    { id: 1, hora: "12", minutes: "30" },
    { id: 1, hora: "13", minutes: "00" },
    { id: 1, hora: "13", minutes: "30" },
    { id: 1, hora: "14", minutes: "00" },
    { id: 1, hora: "14", minutes: "30" },
    { id: 1, hora: "15", minutes: "00" },
    { id: 1, hora: "15", minutes: "30" },
    { id: 1, hora: "16", minutes: "00" },
    { id: 1, hora: "16", minutes: "30" },
    { id: 1, hora: "17", minutes: "00" },
    { id: 1, hora: "17", minutes: "30" },
    { id: 1, hora: "18", minutes: "00" },
    { id: 1, hora: "18", minutes: "30" },
    { id: 1, hora: "19", minutes: "00" },
    { id: 1, hora: "19", minutes: "30" },
    { id: 1, hora: "20", minutes: "00" },
    { id: 1, hora: "20", minutes: "30" },
  ];

  getHora() {
    return this.arrHours;
  }
  getHorario(): Observable<any> {
    return this.http
      .get(this.url + `horarios`, this.settings)
      .pipe(map((result: Response) => result));
  }

  getHorarioById(id: number) {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    return this.http.get<any>(this.url + `horario/${id}`, {
      headers: headers,
    });
  }

  // FORMULARIO CONTACTO AND VALIDATION
  formulario: FormGroup = new FormGroup({
    id: new FormControl(null),
    fecha: new FormControl("", Validators.required),
    hora: new FormControl("", Validators.required),
    duracion: new FormControl(null, Validators.required),
    estado: new FormControl(false, Validators.required),
    id_profesional: new FormControl(null, Validators.required),
    id_especialidad: new FormControl(null, Validators.required),
    id_contacto: new FormControl(null, Validators.required),
  });
  //FORMULARIO RUT, ESPECIALIDAD Y PROFESIONAL
  rutFormGroup: FormGroup;
  secondFormGroup: FormGroup;

  // REINITIALIZE FORM
  initializeFormGroup() {
    this.formulario.setValue({
      id: null,
      fecha: "",
      hora: "",
      duracion: null,
      estado: false,
      id_profesional: null,
      id_especialidad: null,
      id_contacto: null,
    });
  }
  //FORMULARIO ESPECIALIDAD Y PROFESIONAL VALIDATION
  rutFormService() {
    this.rutFormGroup = this._formBuilder.group({
      rutuser: new FormControl("", [
        Validators.required,
        Validators.minLength(9),
      ]),
    });
  }
  //FORMULARIO ESPECIALIDAD Y PROFESIONAL VALIDATION
  secondFormStep() {
    this.secondFormGroup = this._formBuilder.group({
      especialidad: ["", Validators.required],
      profesional: ["", Validators.required],
    });
  }
  insertHorario(horario?: any): Observable<any> {
    let headers = new HttpHeaders().set("Content-Type", "application/json");

    return this.http.post(this.url + "horario", horario, {
      headers: headers,
    });
  }

  updateContacto(horario) {
    console.log(horario);
  }

  //DELETE
  deleteProducto(id: Number) {
    //this.contactList.remove(id);
  }

  populateForm(servicio) {
    const { id, fecha, hora, duracion } = servicio;

    if (localStorage.getItem("especialidad") !== null) {
      this.id_especialidad = JSON.parse(localStorage.getItem("especialidad"));
    }

    if (localStorage.getItem("profesional") !== null) {
      this.id_profesional = JSON.parse(localStorage.getItem("profesional"));
    }

    if (localStorage.getItem("id_contacto") !== null) {
      this.id_contacto = JSON.parse(localStorage.getItem("id_contacto"));
    }

    const agendar_hora = {
      id,
      fecha,
      hora,
      duracion,
      estado: true,
      id_profesional: this.id_profesional,
      id_especialidad: this.id_especialidad,
      id_contacto: this.id_contacto,
    };

    if (localStorage.getItem("horario") === null) {
      localStorage.setItem("horario", JSON.stringify(agendar_hora));
    } else {
      localStorage.removeItem("horario");
      localStorage.setItem("horario", JSON.stringify(agendar_hora));
    }
  }
  identifyUser(rut) {
    console.log(rut);
  }
}
