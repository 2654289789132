import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

// componentes

import { ProfesionalDetailsComponent } from "./pages/profesional/profesional-details/profesional-details.component";
import { CitasDetailsComponent } from "./pages/citas/citas-details/citas-details.component";
import { PacientDetailsComponent } from "./pages/paciente/pacient-details/pacient-details.component";

//GUARDS
import { AuthGuard } from "./guards/auth.guard";

//import { DefaultPage } from "./pages/default/default.page";
// use canLoad as middlewares to protect the routes;

const routes: Routes = [
  {
    path: "inicio",
    loadChildren: () =>
      import("./pages/home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
  {
    path: "tabs",
    loadChildren: () =>
      import("./pages/tabs/tabs.module").then((m) => m.TabsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "pacientes",
    loadChildren: () =>
      import("./pages/paciente/paciente.module").then(
        (m) => m.PacientePageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "pacientes/:id",
    component: PacientDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "pacientes/:id/:service",
    component: PacientDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "horario",
    loadChildren: () =>
      import("./pages/horario/horario.module").then((m) => m.HorarioPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "profesional",
    loadChildren: () =>
      import("./pages/profesional/profesional.module").then(
        (m) => m.ProfesionalPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "profesional/:id",
    component: ProfesionalDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "citas",
    loadChildren: () =>
      import("./pages/citas/citas.module").then((m) => m.CitasPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "citas/:id/:service",
    component: CitasDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "profile",
    loadChildren: () =>
      import("./pages/profile/profile.module").then((m) => m.ProfilePageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/default/default.module").then((m) => m.DefaultPageModule),
  },
  {
    path: "agendamiento",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/agendas/agendas.module").then((m) => m.AgendasPageModule),
  },
  /*{
    path: "pagos",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./pages/pagos/pagos.module").then((m) => m.PagosPageModule),
  },*/
  {
    path: "pagina_404",
    pathMatch: "full",
    loadChildren: () =>
      import("./pages/not-found/not-found.module").then(
        (m) => m.NotFoundPageModule
      ),
  },
  { path: "**", redirectTo: "/pagina_404" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

  /* {
    path: "especialidad",
    loadChildren: () =>
      import("./pages/especialidad/especialidad.module").then(
        (m) => m.EspecialidadPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "especialidad/:id",
    component: EspecialidadDetailsComponent,
    canActivate: [AuthGuard],
  },*/
  /* {
    path: "tratamientos",
    loadChildren: () =>
      import("./pages/tratamientos/tratamientos.module").then(
        (m) => m.TratamientosPageModule
      ),
  },
  {
    path: "tratamientos/:id",
    component: TratamientosDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "tratamientos/:id/:service",
    component: TratamientosDetailsComponent,
    canActivate: [AuthGuard],
  },*/