import { Component, OnInit, OnDestroy } from "@angular/core";
//SERVICIO
import { ProfesionalService } from "../../../services/profesional.service";
//ROUTER
import { Router, ActivatedRoute, Params } from "@angular/router";
//MODELO
import { Producto } from "../../../model/producto.model";

@Component({
  selector: "app-profesional-details",
  templateUrl: "./profesional-details.component.html",
  styleUrls: ["./profesional-details.component.scss"],
  providers: [ProfesionalService],
})
export class ProfesionalDetailsComponent implements OnInit {
  public dentista: any;
  public profesional;
  public load = false;
  public especialidades: Array<{ id: String; especialidad: String }>;
  public links: Array<{ rel: String; href: String; method: string }>;
  buttonBack = "";

  constructor(
    public profesionalservice: ProfesionalService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this._route.params.subscribe((params) => {
      const routeId = Number(params["id"]);
      this.profesionalservice
        .getProfesionalById(routeId)
        .subscribe(async (res: any) => {
          const payload = await res.data;
          this.DentistasLocalStorage(payload);
        });
    });
  }

  ngOnInit() {}

  Profesional(profesional) {
    if (localStorage.getItem("profesional") === null) {
      localStorage.setItem("profesional", JSON.stringify(profesional));
      this.profesional = JSON.parse(localStorage.getItem("profesional"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    } else {
      localStorage.removeItem("profesional");
      localStorage.setItem("profesional", JSON.stringify(profesional));
      this.profesional = JSON.parse(localStorage.getItem("profesional"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    }
    this.especialidades = JSON.parse(this.profesional.especialidades);
  }

  ngOnDestroy() {
    localStorage.removeItem("profesional");
  }

  DentistasLocalStorage(data: any) {
    if (localStorage.getItem("dentista") === null) {
      localStorage.setItem("dentista", JSON.stringify(data));
      this.dentista = JSON.parse(localStorage.getItem("dentista"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    } else {
      localStorage.removeItem("dentista");
      localStorage.setItem("dentista", JSON.stringify(data));
      this.dentista = JSON.parse(localStorage.getItem("dentista"));
      setTimeout(() => {
        // SHOW HTML
        this.load = true;
      }, 500);
    }
    this.links = this.dentista.links;
  }
}
